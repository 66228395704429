import { useClientStore } from '~/stores/clients';
import { DEBUG, GLOBAL_MESSAGE_EVENT, type PayloadDataType } from '.';
import { useHelperStore } from '~/stores/helpers';

type ClientAdminAccess = {
  clientId: number;
};

export async function handleUpdateClientAdminAccess(
  ...[_channel, data]: PayloadDataType<ClientAdminAccess>
) {
  DEBUG && console.log('handlePusherMessages', data);

  const piniaClient = useClientStore();
  const piniaHelper = useHelperStore()
  const emitter = useNuxtApp().$emitter;
  const { status } = piniaHelper.latestResponseStatus as { status: number };

  await piniaClient.getAdminContactsForClient(data.clientId);
  //in order to properly define the notification if the customer user is added or removed from the client, we need a flag in update customer admin.
  const message = 'Customer admin successfully managed the Organization';
  emitter.$emit(GLOBAL_MESSAGE_EVENT, {
    status,
    message,
    eventType: 'CHANNEL_ADMIN_ACCESS_GRANTED',
  });
}
