import { defineStore } from 'pinia';
import { usePinia } from '#imports';
import { handleErrorAndNotificationHandler } from '~/util/errorNotificationHandler';

export const ENTITY_TYPES_ENUM = {
  organization: 'ORGANIZATION',
  studyTeam: 'STUDY_TEAM',
  user: 'USER',
  account: 'ACCOUNT',
  contact: 'CONTACT',
};

export const LIST_TYPES_ENUM = {
  service: 'SERVICE',
  address: 'ADDRESS',
  phone: 'PHONE',
  website: 'WEBSITE',
  prefix: 'PREFIX',
  emailAddress: 'EMAIL_ADDRESS',
  status: 'STATUS',
  suffix: 'SUFFIX',
};

export const POST_DETAIL_REFERRER_ENUM = {
  channel: 'CHANNEL',
  unreadPosts: 'UNREAD_POSTS',
  classifications: 'CLASSIFICATIONS',
};

export const SPECIAL_CHARACTERS = [
  '@',
  '!',
  '#',
  '%',
  '&',
  '*',
  '+',
  '[]',
  '{}',
  '{',
  '}',
  '[',
  ']',
];

export const useHelperStore = defineStore('helper', {
  state: () => ({
    listTypeData: [],
    countryData: [],
    scrollPosition: 0,
    scrollPositionClassifications: 0,
    postDetailReferrer: 'CHANNEL',
    changedEmailAddress: '',
    cardDetailHasThumbnails: false,
    teamId: '',
    routeName: {},
    countries: {},
    breadCrumbsLink: {},
    teamContact: {},
    emailAddress: '',
    siteName: '',
    selection: '',
    updateOption: '',
    allRolesSelected: [],
    postCreate: '',
    activityView: false,
    cardIndex: {},
    publicTos: '',
    loader: { show: false },
    triggerPopup: true,
    patchedCompliance: false,
    patchedComplianceRule: '',
    classPatched: false,
    classificationCategories: [],
    sharingTeamIds: [],
    sharingPatched: false,
    sideBarCollapsed: false,
    postCreationPostIds: [],
    profileImg: {},
    avatarImgFetched: false,
    hasViewed: false,
    openInviteDialog: false,
    openStudySite: false,
    openOtherTeam: false,
    tableToReturnTo: {},
    latestResponseStatus: {},
    refetchInvitedTable: false,
  }),
  actions: {
    setLatestResponseStatus(status) {
      try {
        this.latestResponseStatus = JSON.parse(status);
      } catch (e) {
        // not a valid json, happens from interceptor registered in store plugin
        // not really relevant, as we use it for auth only
      }
    },
    updateHasViewedFile(payload) {
      this.hasViewed = payload;
    },
    async fetchProfileImg() {
      try {
        const resp = await usePinia().$axios.$get(
          usePinia().$API.user.profileImage()
        );
        this.profileImg = resp;
      } catch (error) {
        handleErrorAndNotificationHandler(
          'Error fetching profile image:',
          error
        );
      }
    },
    imgFetched(payload) {
      this.avatarImgFetched = payload;
    },
    updateLoader(payload) {
      this.loader = {
        ...this.loader,
        ...payload,
      };
    },
    triggerPopup(payload) {
      this.triggerPopup = payload;
    },
    syncListTypeData(listTypeData) {
      this.listTypeData = listTypeData;
    },
    resetWizard() {
      this.postCreate = {};
      this.emailAddress = '';
      this.patchedCompliance = false;
      this.patchedComplianceRule = '';
      this.sharingPatched = false;
    },
    syncCountryData(countryData) {
      this.countryData = countryData.map((c) => {
        const name = c.name;
        if (name.startsWith('country.')) {
          const countryName = name.substring('country.'.length);
          const updateCountryName = countryName
            .replace(/-/g, ' ')
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
          console.log('countryName', countryName);
          console.log('blah', updateCountryName);

          return {
            ...c,
            name: updateCountryName,
          };
        } else {
          return { ...c };
        }
      });
    },
    getType(types) {
      return this.listTypeData.filter(
        (t) =>
          t.entityName === types.entity && t.listTypeName === types.listType
      );
    },
    setScrollPosition(scrollPosition) {
      this.scrollPosition = scrollPosition;
    },
    setScrollPositionClassifications(scrollPositionClassifications) {
      this.scrollPositionClassifications = scrollPositionClassifications;
    },
    savePostDetailReferrer(referrerPage) {
      this.postDetailReferrer = referrerPage;
    },
    changedEmailAddress(changedEmailAddress) {
      this.changedEmailAddress = changedEmailAddress;
    },
    setCardDetailHasThumbnails(hasThumbnails) {
      this.cardDetailHasThumbnails = hasThumbnails;
    },
    setTeamId(teamId) {
      this.teamId = teamId;
    },
    setRouteName(routeName) {
      this.routeName = routeName;
    },
    viewActivity(activityView) {
      this.activityView = activityView;
    },
    getCountries(country) {
      this.countries = country;
    },
    breadcrumbsLink(breadCrumbsLink) {
      this.breadCrumbsLink = breadCrumbsLink;
    },
    setTeamContact(teamContact) {
      this.teamContact = teamContact;
    },
    storeEmailAddress(emailAddress) {
      this.emailAddress = emailAddress;
    },
    setStudySiteTeamName(siteName) {
      this.siteName = siteName;
    },
    selectedValue(selection) {
      this.selection = selection;
    },
    updateOptionFn(updateOption) {
      this.updateOption = updateOption;
    },
    allRolesSelected(allRolesSelected) {
      this.allRolesSelected = allRolesSelected;
    },
    postCreationWizard(postCreate) {
      this.postCreate = postCreate;
    },
    summaryCardIndex(cardIndex) {
      this.cardIndex = cardIndex;
    },
    async publicTermsOfService() {
      try {
        const resp = await usePinia().$axios.$get(
          usePinia().$API.app.termsOfService
        );
        this.publicTos = resp.content;
      } catch (error) {
        handleErrorAndNotificationHandler(
          'Error fetching public terms of service:',
          error
        );
      }
    },
    postCreationCompliancePatched(payload) {
      this.patchedCompliance = payload;
    },
    postCreationComplianceRules(payload) {
      this.patchedComplianceRule = payload;
    },
    classificationPostPatched(payload) {
      this.classPatched = payload;
    },
    classificationItems(payload) {
      this.classificationCategories = payload;
    },
    sharingScreenUpdated(payload) {
      this.sharingTeamIds = payload;
    },
    sharingPatched(payload) {
      this.sharingPatched = payload;
    },
    collapseSideBar(payload) {
      this.sideBarCollapsed = payload;
    },
    postCreationPostIds(payload) {
      this.postCreationPostIds = payload;
    },
    openInviteMemberDialog(payload) {
      this.openInviteDialog = payload;
    },
    openStudySiteCreation(payload) {
      this.openStudySite = payload;
    },
    openOtherTeamCreation(payload) {
      this.openOtherTeam = payload;
    },
    returnToTable(payload) {
      this.tableToReturnTo = payload;
    },
    clearReturnToTable() {
      this.tableToReturnTo = {};
    },
    refetchInvitedMemberTable(payload) {
      this.refetchInvitedTable = payload;
    },
  },

  getters: {
    servicesGetter: (state) =>
      state.listTypeData.filter(
        (l) => l.listTypeName === LIST_TYPES_ENUM.service
      ),
    addressGetter: (state) =>
      state.listTypeData.filter(
        (l) =>
          l.listTypeName === LIST_TYPES_ENUM.address &&
          l.entityName === ENTITY_TYPES_ENUM.user
      ),
    phoneGetter: (state) =>
      state.listTypeData.filter(
        (l) => l.listTypeName === LIST_TYPES_ENUM.phone
      ),
    websiteGetter: (state) =>
      state.listTypeData.filter(
        (l) => l.listTypeName === LIST_TYPES_ENUM.website
      ),
    prefixGetter: (state) =>
      state.listTypeData.filter(
        (l) =>
          l.listTypeName === LIST_TYPES_ENUM.prefix &&
          l.entityName === ENTITY_TYPES_ENUM.user
      ),
    emailAddressGetter: (state) =>
      state.listTypeData.filter(
        (l) => l.listTypeName === LIST_TYPES_ENUM.emailAddress
      ),
    statusGetter: (state) =>
      state.listTypeData.filter(
        (l) => l.listTypeName === LIST_TYPES_ENUM.status
      ),
    suffixGetter: (state) =>
      state.listTypeData.filter(
        (l) =>
          l.listTypeName === LIST_TYPES_ENUM.suffix &&
          l.entityName === ENTITY_TYPES_ENUM.user
      ),
    countryGetter: (state) => state.countryData,
    loaderGetter: (state) => state.loader,
  },
});
