import type Pubsub from '../pubsub';
import { pubsubEvents } from '../pubsub';

import { handleAddDraft } from './createDraftPostMessage';
import { handlePublishPost } from './publishPostMessage';
import { handleCreateTeam } from './createTeamMessage';
import { handleCreateMember } from './createMemberMessage';
import { handleDeleteMember } from './deleteMemberMessage';
import { handleUpdateTeam } from './updateTeamMessage';
import { handleUpdatePost } from './updatePostMessage';
import { handleCreateChannel } from './createChannelMessage';
import { handleUpdateChannel } from './updateChannelMessage';
import { handleUserChannels } from './updateUserMessage';
import { handleClientAdminAccess } from './clientAdminAccess';
import { handleUpdateClientAdminAccess } from './editClientAdmins';

export const DEBUG: boolean = true as const;

export const GLOBAL_MESSAGE_EVENT = 'toast-global-message' as const;

type PusherSocket = unknown; // not really relevant, we don't use this Socket info anywhere

export type PayloadDataType<T> = [PusherSocket, T];

export function handlePusherMessages(pubsub: Pubsub) {
  pubsub
    .subscription(pubsubEvents.CHANNEL_ACCESS_GRANTED, handleUserChannels)
    .subscribe();
  pubsub
    .subscription(pubsubEvents.CHANNEL_ACCESS_REMOVED, handleUserChannels)
    .subscribe();
  pubsub.subscription(pubsubEvents.ADD_DRAFT, handleAddDraft).subscribe();
  pubsub.subscription(pubsubEvents.PUBLISH_POST, handlePublishPost).subscribe();
  pubsub.subscription(pubsubEvents.UPDATE_POST, handleUpdatePost).subscribe();
  pubsub.subscription(pubsubEvents.CREATE_TEAM, handleCreateTeam).subscribe();
  pubsub.subscription(pubsubEvents.UPDATE_TEAM, handleUpdateTeam).subscribe();
  pubsub.subscription(pubsubEvents.UPDATE_CLIENT_ADMINS, handleUpdateClientAdminAccess).subscribe();
  pubsub.subscription(pubsubEvents.CLIENT_ADMIN_ACCESS_GRANTED, handleClientAdminAccess).subscribe();
  pubsub.subscription(pubsubEvents.CLIENT_ADMIN_ACCESS_REMOVED, handleClientAdminAccess).subscribe();

  pubsub
    .subscription(pubsubEvents.CREATE_MEMBER, handleCreateMember)
    .subscribe();
  pubsub
    .subscription(pubsubEvents.DELETE_MEMBER, handleDeleteMember)
    .subscribe();
  pubsub
    .subscription(pubsubEvents.CREATE_CHANNEL, handleCreateChannel)
    .subscribe();
  pubsub
    .subscription(pubsubEvents.UPDATE_CHANNEL, handleUpdateChannel)
    .subscribe();

}
