import { defineStore } from 'pinia';
import { usePinia } from '#imports';
import { handleErrorAndNotificationHandler } from '~/util/errorNotificationHandler';

export const useUserStore = defineStore('user', {
  state: () => ({
    user: null,
  }),
  actions: {
    setUser(user) {
      this.user = user;
    },
    async updateUser(userObject) {
      try {
        const pinia = usePinia();
        const resp = await pinia.$axios.$patch(
          pinia.$API.user.updateUserProfile(),
          userObject
        );
        this.user.summary.acceptedTermsOfService = resp.acceptedTermsOfService;
        this.user.summary.verifiedEmail = resp.verifiedEmail;
        this.user.summary.missingInviteAsNewUser = resp.missingInviteAsNewUser;
        this.user.summary.lastLoginDateTimeMillis =
          resp.lastLoginDateTimeMillis;
        this.user.summary.basicUserInfo = resp.basicUserInfo;
        this.user.summary.contactData = resp.contactData;
      } catch (error) {
        handleErrorAndNotificationHandler('Error updating user:', error);
      }
    },

    removeClient(clientId) {
      this.user.clients.splice((e) => e.clientId === clientId);
    },
    async getUserClients(clientId) {
      try {
        const pinia = usePinia();
        const resp = await pinia.$axios.$get(
          pinia.$API.clients.getClient(clientId)
        );
        console.log('response in clients', resp);
        if (this.user.clients.clientId !== resp.clientId) {
          this.user.clients.push(resp);
        }
      } catch (error) {
        handleErrorAndNotificationHandler(
          'Error fetching clients for the user',
          error
        );
      }
    },
    async getUser(fetchUser) {
      try {
        const pinia = usePinia();
        const resp = await pinia.$axios.$get(
          pinia.$API.user.updateUserProfile(),
          fetchUser
        );
        this.user.summary = resp;
      } catch (error) {
        handleErrorAndNotificationHandler('Error fetching user:', error);
      }
    },
  },
  getters: {
    getUserGetter: (state) => {
      return state.user;
    },
  },
});
