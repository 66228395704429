import { DEBUG, GLOBAL_MESSAGE_EVENT, type PayloadDataType } from '.';
import { useHelperStore } from '~/stores/helpers';
import { useUserStore } from '~/stores/auth';

type ClientAdminAccess = {
  eventType: 'CLIENT_ADMIN_ACCESS_GRANTED' | 'CLIENT_ADMIN_ACCESS_REMOVED';
  userId: number;
  auditUserId: number;
  userName: string;
  clientId: number;
  clientUuid: string;
};

const clientRemoved = (clientId: number) => {
  const piniaUser = useUserStore();
  const piniaHelper = useHelperStore();
  const emitter = useNuxtApp().$emitter;
  const route = useRoute();

  const { status } = piniaHelper.latestResponseStatus as { status: number };

  const removedClient = piniaUser.user.clients?.find(
    (e) => e.clientId === clientId
  );
  piniaUser.removeClient(clientId);
  const message = `You have been removed from the ${removedClient.name}.`;
  if (route.name.includes('settings-clientId')) {
    navigateTo('/');
  }

  emitter.$emit(GLOBAL_MESSAGE_EVENT, {
    status,
    message,
    eventType: 'CHANNEL_ADMIN_ACCESS_REMOVED',
  });
};

const clientAdded = async (clientId: number) => {
  const piniaUser = useUserStore();
  const piniaHelper = useHelperStore();
  const emitter = useNuxtApp().$emitter;

  const { status } = piniaHelper.latestResponseStatus as { status: number };
  await piniaUser.getUserClients(clientId);
  const addedClient = piniaUser.user.clients?.find(
    (e) => e.clientId === clientId
  );

  const message = `You have been added to the ${addedClient.name}.`;
  emitter.$emit(GLOBAL_MESSAGE_EVENT, {
    status,
    message,
    eventType: 'CHANNEL_ADMIN_ACCESS_GRANTED',
  });
};

export async function handleClientAdminAccess(
  ...[_channel, data]: PayloadDataType<ClientAdminAccess>
) {
  DEBUG && console.log('handlePusherMessages', data);

  if (data.eventType === 'CLIENT_ADMIN_ACCESS_GRANTED') {
    await clientAdded(data.clientId);
  }
  if (data.eventType === 'CLIENT_ADMIN_ACCESS_REMOVED') {
    clientRemoved(data.clientId);
  }
}
